<template>
	<button
			class="button-unit"
			:type="this.options?.type ?? 'button'"
			:class="{
			[this.options?.class ?? '']: true,
			'button-unit--secondary': this.options?.color === 'secondary',
			'button-unit--success': this.options?.color === 'success',
			'button-unit--error': this.options?.color === 'error',
			'button-unit--info': this.options?.color === 'info',
			'button-unit--warning': this.options?.color === 'warning',
			'only-icon': !this.options?.hasOwnProperty('text'),
		}"
			:disabled="this.options?.disabled"
			@click.prevent="$emit('clickOnBtn')"
	>
        <span class="button-unit__icon">
            <slot name="icon"></slot>
        </span>
		<span v-if="this.options?.text?.length > 0">
            {{ this.options?.text }}
        </span>
	</button>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {ButtonUnitOptions} from "../Interfaces/ButtonUnitOptions";

export default defineComponent({
	name: "ButtonUnit",
	emits: ['clickOnBtn'],
	props: {
		options: {
			type: Object as PropType<ButtonUnitOptions>,
			required: true,
		}
	},
});
</script>

<style scoped>
</style>
