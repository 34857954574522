<template>
	<template v-if="this.options?.hasOwnProperty('name') && this.options?.name?.length > 0">
		<label
				:for="this.options?.slug"
				class="dropdown-unit__label"
				:class="{'required': this.options?.required}"
		>
            <span>
                {{ this.options?.name }}
            </span>
			<span v-if="this.options?.helper" class="vue-select-unit__label--secondary">
				<i class="fa-regular fa-circle-question" @mouseover="tooltipState = true" @mouseleave="tooltipState = false"></i>
				<div class="tooltip" :class="tooltipState ? 'tooltip--show' : 'tooltip--hide'">{{ this.options?.helper }}</div>
			</span>
			<span
					v-if="this.options?.hasOwnProperty('secondaryLabel') && this.options?.secondaryLabel?.length > 0"
					class="dropdown-unit__label--secondary"
			>
                {{ this.options?.secondaryLabel }}
            </span>
		</label>
	</template>

	<div
			class="dropdown-unit__container"
			:class="{
            [this.options?.class ?? '']: true,
            'focused': this.focused,
            'readonly': this.options?.readonly ?? false,
            'dropdown-unit--success': this.options?.successState,
            'dropdown-unit--error': this.options?.errorState,
            'dropdown-unit--warning': this.options?.warningState,
            'dropdown-unit--info': this.options?.infoState,
        }"
	>

		<select
				class="dropdown-unit"
				:name="this.options?.slug"
				:value="this.modelValue"
				:id="this.options?.slug"
				:required="this.options?.required ?? false"
				:disabled="this.options?.readonly ?? false"
				:multiple="this.options?.multiple ?? false"
				:size="this.options?.multiple ? 2 : 0"
				@input="this.onInput($event)"
				@focus="this.focused=true"
				@blur="this.focused=false"
		>
			<option
					v-if="this.options?.placeholder && this.options?.placeholder?.length !== 0"
					value=""
					selected
					disabled
					hidden="hidden"
			>
				{{ this.options?.placeholder }}
			</option>
			<option
					v-for="option in this.options?.options"
					:value="option.value"
					:selected="option.selected || this.modelValue === option.value"
					:disabled="option.disabled ?? false"
					:hidden="option.hidden ? 'hidden' : null"
			>
				{{ option.title }}
			</option>
		</select>

		<span v-if="!this.options?.multiple" class="dropdown-unit__suffix">
            <i class="fa-solid fa-chevron-down"></i>
        </span>
	</div>

	<small
			v-show="this.options?.hasOwnProperty('infoText') && this.options?.infoText?.length > 0"
			:id="this.options?.slug+'_info'"
			class="mt-1 dropdown-unit__label--secondary"
	>
		{{ this.options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {DropdownUnitOptions} from "../Interfaces/DropdownUnitOptions";

export default defineComponent({
	name: "DropdownUnit",
	props: {
		options: {
			type: Object as PropType<DropdownUnitOptions>,
			required: true,
		},
		modelValue: {
			type: [String, Number, Array],
			default: '',
			required: true
		},
	},
	data() {
		return {
			focused: false,
			tooltipState: false,
		}
	},
	methods: {
		onInput(ev: any): void {
			if (this.options?.multiple) {
				const value = [...(ev.target.options as HTMLOptionsCollection)].filter((op: any) => op.selected).map(op => op.value);
				this.$emit("update:modelValue", value);
			} else {
				this.$emit("update:modelValue", ev.target.value);
			}
		},
	},
});
</script>

<style scoped>
</style>
