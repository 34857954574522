<template>
	<v-modal :name="this.modalId" :header="modalHeader" :maxWidth="'1000px'" :passed-data="(passedData) => this.onModalPassedData(passedData)">
		<template #body>
			<div class="row gap-3 justify-content-end">
				<div>
					<dropdown-unit
							v-model="selectedLanguage"
							:options="{
                                    slug: 'language',
                                    options: this.languages,
                                }"
					/>
				</div>
				<button-unit
						@clickOnBtn="copyNews()"
						type="submit"
						class="btn btn--primary btn--rounded mx-2"
						:options="{
							disabled: loading,
							text: `Másolás`,
						}"
				/>
			</div>
		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DropdownUnit from "../../Units/DropdownUnit.vue";
import VModal from "../../VModal.vue";
import InputUnit from "../../Units/InputUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import {CopyNewsPassDataInterface} from "./Interfaces/CopyNewsPassData";

export default defineComponent({
	name: "CopyNewsModal",
	inject: ['emitter'],
	components: {
		VModal,
		DropdownUnit,
		InputUnit,
		ButtonUnit
	},
	computed: {
		modalHeader(): string {
			return `${this.news?.title ?? 'Ismeretlen'} másolása`
		}
	},
	data() {
		return {
			modalId: 'copy-news-modal',
			news: null,
			languages: [],
			onSuccess: null as Function | null,
			loading: false,
			errors: null as Object | null,

			selectedLanguage: 'hu' as string,
		}
	},
	methods: {
		closeModal: () => {
			// @ts-ignore
			this.emitter.emit('onModalClose_' + this.modalId);
		},
		onModalPassedData(passedData: CopyNewsPassDataInterface): void {
			this.languages = passedData.languages;
			this.news = passedData.news;
			this.onSuccess = passedData.onSuccess;

			this.resetFields();
		},
		resetFields(): void {
			this.selectedLanguage = 'hu';
			this.errors = null;
		},
		copyNews(): void {
			if (!this.news) {
				return;
			}

			this.loading = true;
			this.errors = null;
			axios.post(`/api/news/copy/${this.news.id}/${this.selectedLanguage}`).then((response: any) => {
				if (response.status === 200 && response.data.success === true) {
					this.onSuccess && this.onSuccess(response.data?.item ?? {}, this.selectedLanguage);
				}
			}).catch((error: any) => {
				this.errors = error.response?.data?.errors;
				if (!this.errors) {
					Sweetalert2.fire('Hiba történt!', error.response?.data?.message || 'Valami hiba történt!', 'error');
				}

			}).finally(() => {
				// TODO: ??
				this.loading = false;
			});
		}
	}
})

</script>

<style scoped>

</style>
