import {PageComponentEnum} from "../Enums/PageComponentEnum";
import {PageInterface} from "./PageInterface";

export interface PageComponentInterface {
	id: number | string,
	title: string,
	page_id: number,
	type: PageComponentEnum,
	data: any,
	status: boolean,
	sort: number,
	secured: boolean,
	page?: PageInterface,
	show_title: boolean,
}

export class PageComponentClass implements PageComponentInterface {
	id = -1;
	data = [];
	page_id = -1;
	secured = true;
	sort = -1;
	status = false;
	title = 'Új komponens';
	type = PageComponentEnum.HIGHLIGHTED_BLOCK;
	show_title = false;
}
