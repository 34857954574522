<template>
	<div class="w-100 h-100 d-flex justify-content-center align-items-center">
		Kérlek válassz ki egy menüpontot a bal oldali listából!
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ViewContainer from "../ViewContainer.vue";

export default defineComponent({
	name: "DashboardView",
});
</script>

<style scoped>
</style>
