<template>
	<template v-if="this.options?.hasOwnProperty('name') && this.options?.name?.length > 0">
		<label
				:for="this.options?.slug"
				class="input-unit__label"
				:class="{'required': this.options?.required}"
		>
            <span>
                {{ this.options?.name }}
            </span>
			<span v-if="this.options?.helper" class="vue-select-unit__label--secondary">
				<i class="fa-regular fa-circle-question" @mouseover="tooltipState = true" @mouseleave="tooltipState = false"></i>
				<div class="tooltip" :class="tooltipState ? 'tooltip--show' : 'tooltip--hide'">{{ this.options?.helper }}</div>
			</span>
			<span
					v-if="this.options?.hasOwnProperty('secondaryLabel') && this.options?.secondaryLabel?.length > 0"
					class="input-unit__label--secondary"
			>
                {{ this.options?.secondaryLabel }}
            </span>
		</label>
	</template>

	<div
			class="input-unit__container"
			:class="{
			[this.options?.class ?? '']: true,
			'focused': this.focused,
			'readonly': this.options?.readonly ?? false,
			'input-unit--success': this.options?.successState ?? false,
			'input-unit--error': this.options?.errorState ?? false,
			'input-unit--warning': this.options?.warningState ?? false,
			'input-unit--info': this.options?.infoState ?? false,
		}"
	>
		<span class="input-unit__prefix" @click="this.focusOnInput">
			<slot name="prefix"></slot>
		</span>
		<input
				ref="input"
				:type="this.options?.type ?? 'text'"
				:name="this.options?.slug"
				:id="this.options?.slug"
				class="input-unit"
				v-maska="this.options?.mask"
				:value="this.modelValue"
				:placeholder="this.options?.placeholder ?? ''"
				:minlength="this.options?.minlength ?? null"
				:maxlength="this.options?.maxlength ?? null"
				:pattern="this.options?.pattern ?? null"
				:min="this.options?.min ?? null"
				:max="this.options?.max ?? null"
				:step="this.options?.step ?? null"
				:accept="this.options?.accept ?? null"
				:required="this.options?.required ?? false"
				:readonly="(this.options?.readonly || this.options?.class?.includes('only-text')) ?? false"
				@input='this.$emit("update:modelValue", $event.target.value)'
				@change='this.$emit("fileChange", $event)'
				@focus="this.focused=true"
				@blur="this.focused=false"
		/>
		<span class="input-unit__suffix" @click="this.focusOnInput">
			<slot name="suffix"></slot>
		</span>
	</div>

	<small
			v-show="this.options?.hasOwnProperty('infoText') && this.options?.infoText?.length > 0"
			:id="this.options?.slug+'_info'"
			class="mt-1 input-unit__label--secondary"
	>
		{{ this.options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {InputUnitOptions} from "../Interfaces/InputUnitOptions";

export default defineComponent({
	name: "InputUnit",
	emits: ["update:modelValue", "fileChange"],
	props: {
		options: {
			type: Object as PropType<InputUnitOptions>,
			required: true,
		},
		modelValue: {
			type: [String, Number],
			default: '',
			required: false,
		},
	},
	data() {
		return {
			focused: false,
			tooltipState: false,
		}
	},
	methods: {
		focusOnInput(): void {
			(this.$refs.input as unknown as HTMLInputElement).focus();
		},
	},
});
</script>

<style scoped>
</style>
