<template>
	<v-modal :name="this.modalId" :header="modalHeader" :maxWidth="'1000px'" :passed-data="(passedData) => this.onModalPassedData(passedData)">
		<template #body>
			<div class="row gap-3 justify-content-end">
				<div>
					<input-unit
							:options="{
										name: 'Új oldal neve',
										slug: 'page-title',
										placeholder: 'Új oldal neve',
										errorState: errors && errors['title']?.length > 0,
										infoText: errors && (Array.isArray(errors['title']) ? errors['title'].join(', ') : errors['title']),
										required: true
									}"
							v-model="pageCopyData.title"
					/>
				</div>
				<div>
					<input-unit
							:options="{
										name: 'URL',
										slug: 'page-url',
										placeholder: 'URL',
										errorState: errors && errors['permalink']?.length > 0,
										infoText: errors && (Array.isArray(errors['permalink']) ? errors['permalink'].join(', ') : errors['permalink']),
										required: true,
									}"
							v-model="pageCopyData.permalink"
					/>
				</div>
				<div>
					<dropdown-unit
							v-model="selectedLanguage"
							:options="{
									name: 'Nyelv',
                                    slug: 'language',
                                    options: this.languages,
                                }"
					/>
				</div>
				<button-unit
						@clickOnBtn="copyPage()"
						type="submit"
						class="btn btn--primary btn--rounded mx-2"
						:options="{
							disabled: loading,
							text: `Másolás`,
						}"
				/>
			</div>
		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DropdownUnit from "../../Units/DropdownUnit.vue";
import VModal from "../../VModal.vue";
import InputUnit from "../../Units/InputUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import {PageClass, PageInterface} from "../../../Views/PageModule/Interfaces/PageInterface";
import {CopyPageRequestDataClass, CopyPageRequestDataInterface} from "./Interfaces/CopyPageRequestData";
import {CopyPagePassDataInterface} from "./Interfaces/CopyPagePassData";

export default defineComponent({
	name: "CopyPageModal",
	inject: ['emitter'],
	components: {
		VModal,
		DropdownUnit,
		InputUnit,
		ButtonUnit
	},
	computed: {
		modalHeader(): string {
			return `${this.page?.title ?? 'Ismeretlen'} másolása`
		}
	},
	data() {
		return {
			modalId: 'copy-page-modal',
			page: null as PageInterface | null,
			languages: [],
			onSuccess: null as Function | null,
			loading: false,
			errors: null as Object | null,

			selectedLanguage: 'hu' as string,
			pageCopyData: new CopyPageRequestDataClass(),
		}
	},
	methods: {
		closeModal: () => {
			// @ts-ignore
			this.emitter.emit('onModalClose_' + this.modalId);
		},
		onModalPassedData(passedData: CopyPagePassDataInterface): void {
			this.languages = passedData.languages;
			this.page = passedData.page;
			this.onSuccess = passedData.onSuccess;

			this.resetFields();
		},
		resetFields(): void {
			this.pageCopyData = new CopyPageRequestDataClass();
			this.selectedLanguage = 'hu';
			this.errors = null;
		},
		copyPage(): void {
			if (!this.page) {
				return;
			}

			this.loading = true;
			this.errors = null;
			axios.post(`/api/pages/copy/${this.page.id}/${this.selectedLanguage}`, this.pageCopyData).then((response: any) => {
				if (response.status === 200 && response.data.success === true) {
					this.onSuccess && this.onSuccess(response.data?.item ?? new PageClass());
				}
			}).catch((error: any) => {
				this.errors = error.response?.data?.errors;
				if (!this.errors){
					Sweetalert2.fire('Hiba történt!', error.response?.data?.message || 'Valami hiba történt!', 'error');
				}

			}).finally(() => {
				// TODO: ??
				this.loading = false;
			});
		}
	}
})

</script>

<style scoped>

</style>
