import './bootstrap.js';
// @ts-ignore
import {createApp, h} from 'vue/dist/vue.esm-bundler';
import {createPinia} from 'pinia';

import App from "./Layouts/App.vue";
import router from "./router";
import {VueReCaptcha} from "vue-recaptcha-v3";
import mitt from "mitt";
import vSelect from "vue-select";
import Maska from 'maska'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import TinyMceMixin from "./Mixins/TinyMceMixin";
import axios from "axios";

// @ts-ignore
const env = import.meta.env;

const app = createApp({
	render: () => h(App),
	created() {
		axios.post('/api/config/captcha', {keys: ['google_recaptcha_site_key']}).then((response: any) => {
			if (response.status === 200 && response.data.success === true) {
				//@ts-ignore
				app.use(VueReCaptcha, {siteKey: response.data.items.google_recaptcha_site_key});
			}
		}).catch((error: any) => {
			console.log(error);
			console.log('google_recaptcha_site_key error');
		});
	},
	data() {
		return {
			editorApiKey: '',
			editorConfig: {
				menubar: true,
				image_class_list: [
					{title: 'Responsive', value: 'tiny-img-responsive'}
				],
				external_plugins: {
					inserter: '/build/assets/tinymce/js/inserter-plugin.js'
				},
				entity_encoding: "raw",
				language: 'hu_HU',
				forced_root_block: "",
				plugins: 'importcss template print preview importcss searchreplace autolink autosave directionality visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap emoticons inserter code',
				toolbar: [
					'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist a11ycheck ltr rtl',
					'inserter forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | code'
				],
				images_file_types: 'png,gif,jpg,svg,webp',
				automatic_uploads: true,
				deprecation_warnings: false,
				images_upload_handler: this.imageHandler,
				statusbar: false,
				height: "480",
				advcode_inline: true,
				content_css: '/build/assets/tinymce/css/content-style.css',
				font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; Poppins=poppins; Open Sans=open sans;',
				templates: [
					{
						title: 'Automatikus horizontális elrendezés',
						description: '',
						content: `<div class="row g-3"><div class="col-12 col-sm"></div></div>`
					},
					{
						title: 'Doboz',
						description: '',
						content: `<div class="v-card">Tartalom</div>`
					},
					{
						title: 'Gomb',
						description: '',
						content: `<button type="button" class="d-inline button-unit shape-rounded" style="color: #fff;background: var(--color-profile);"><span>Gomb neve</span></button>`
					},
				]
			}
		}
	}
});


app.use(createPinia());
app.use(router);
app.use(Maska)


export const emitter = mitt();

app.provide('emitter', emitter);
app.component('v-select', vSelect);

if (env.VITE_MIX !== 'staging' && env.VITE_MIX !== 'local') {
	Sentry.init({
		app: app,
		dsn: env.VITE_SENTRY_DSN_PUBLIC,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracePropagationTargets: ["localhost", "ph-template.rc.relative.hu", "admin.ph-template.rc.relative.hu", /^\//],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
	app.mixin(Sentry.createTracingMixins({hooks: [], timeout: 0, trackComponents: true}));
	// @ts-ignore
	Sentry.attachErrorHandler(app, {attachProps: false, hooks: [], timeout: 0, trackComponents: undefined, logErrors: true});
}


app.mount("#app");
