export interface PageSeoInterface {
	key: string,
	value: string
}

export const DefaultPageSeo: PageSeoInterface[] = [
	{key: 'title', value: ''},
	{key: 'description', value: ''},
	{key: 'keywords', value: ''},
	{key: 'og:title', value: ''},
	{key: 'og:description', value: ''},
	{key: 'og:image', value: ''},
	{key: 'twitter:title', value: ''},
	{key: 'twitter:description', value: ''},
	{key: 'twitter:image', value: ''}
];
