<template>
	<router-view/>
	<v-loader/>
	<copy-page-modal/>
	<copy-news-modal/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VLoader from "../Components/VLoader.vue";
import CopyPageModal from "../Components/Modals/CopyPageModal/CopyPageModal.vue";
import CopyNewsModal from "../Components/Modals/CopyNewsModal/CopyNewsModal.vue";

export default defineComponent({
	name: "App",
	components: {CopyPageModal, CopyNewsModal, VLoader},
});
</script>

<style scoped>
</style>
