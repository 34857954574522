import {PageComponentClass, PageComponentInterface} from "./PageComponentInterface";
import {DefaultPageSeo, PageSeoInterface} from "./PageSeoInterface";
import {PageTypeEnum} from "../Enums/PageTypeEnum";

export interface PageInterface {
	id: number | null,
	title: string,
	permalink: string,
	lang: string,
	type: PageTypeEnum,
	seo: PageSeoInterface[],
	parent_id: number,
	status: boolean,
	secured: boolean
	components: PageComponentInterface[],
}

export class PageClass implements PageInterface {
	id = null;
	components = [new PageComponentClass()];
	lang = '';
	parent_id = -1;
	permalink = '';
	secured = true;
	seo = DefaultPageSeo as PageSeoInterface[];
	status = true;
	title = '';
	type = PageTypeEnum.LANDING;

}
